var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('p',{staticClass:"text-h6"},[_vm._v("Customer Landscape Quality Sentiment")]),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"12"}},[_c('v-rating',{attrs:{"hover":"","half-increments":"","length":"5","readonly":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('img',{attrs:{"height":"58","width":"49","src":props.isFilled
                    ? 'media/assets/active-leaf.png'
                    : props.isHalfFilled
                    ? 'media/assets/half-leaf.png'
                    : 'media/assets/inactive-leaf.png'}})]}}]),model:{value:(_vm.rating),callback:function ($$v) {_vm.rating=$$v},expression:"rating"}}),_c('div',{staticClass:"sentiment-number-box ml-5 pa-3 rounded-circle d-flex justify-center align-center black--text"},[_c('span',{staticClass:"text-h5 font-weight-black"},[_vm._v(_vm._s(_vm.rating)+"/5")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-radio-group',{attrs:{"row":"","dense":""},model:{value:(_vm.customerStatus),callback:function ($$v) {_vm.customerStatus=$$v},expression:"customerStatus"}},[_c('v-radio',{staticClass:"v-radio-no-margin",attrs:{"label":"Show All","color":"primary","value":"all"}}),_c('v-radio',{staticClass:"v-radio-no-margin",attrs:{"label":"Show Active","color":"primary","value":"active"}}),_c('v-radio',{staticClass:"v-radio-no-margin",attrs:{"label":"Show Inactive","color":"primary","value":"inactive"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"dense":"","items":_vm.branchesItems,"item-text":"name","item-value":"value","label":"View By Branch..."},model:{value:(_vm.viewByBranch),callback:function ($$v) {_vm.viewByBranch=$$v},expression:"viewByBranch"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"3"}},[(
              _vm.permissions.quarterlyPlanTemplate &&
                _vm.permissions.quarterlyPlanTemplate.canCreate
            )?_c('router-link',{staticClass:"ml-4",attrs:{"to":"/planTemplates"}},[_c('v-btn',{staticClass:"orange-bg white--text",attrs:{"small":""}},[_vm._v("Quarterly Plan Templates")])],1):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","md":"4"}},[_c('v-spacer'),_c('div',[_c('v-img',{attrs:{"src":"media/assets/customers-legend.png","max-height":"150","max-width":"200"}})],1)],1)],1),_c('v-data-table',{staticClass:"customers-table elevation-1",attrs:{"headers":_vm.headers,"items":_vm.customers,"item-key":"id","loading":_vm.isLoading,"server-items-length":_vm.totalCustomers,"options":_vm.options,"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.sentimentWarning",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":("/customers/" + (item.aspireId))}},[(item.isActive && item.sentiment.value < 4)?_c('img',{key:"sentiment_less_4",attrs:{"width":"17px","height":"20px","src":"media/assets/orange-leaf.png"}}):_vm._e()])]}},{key:"item.communityName",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"blue-color",attrs:{"to":("/customers/" + (item.aspireId))}},[_vm._v(" "+_vm._s(item.communityName)+" ")])]}},{key:"item.propertyManagerEmail",fn:function(ref){
            var item = ref.item;
return [_c('a',{staticClass:"blue-color",attrs:{"href":'mailto:' + item.propertyManagerEmail}},[_vm._v(_vm._s(item.propertyManagerEmail))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(item.statusDialog),callback:function ($$v) {_vm.$set(item, "statusDialog", $$v)},expression:"item.statusDialog"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_vm._v("Are you sure you want to make customer "+_vm._s(item.communityName)+" "+_vm._s(item.isActive ? "inactive" : "active")+"? ")]),_c('v-card-actions',[_c('v-spacer'),(!_vm.statusProcessing)?_c('v-btn',{key:"status_processing",attrs:{"color":"green darken-1","text":""},on:{"click":function($event){item.statusDialog = false}}},[_vm._v(" Cancel ")]):_vm._e(),_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.statusProcessing},on:{"click":function($event){return _vm.toggleStatus(item)}}},[_vm._v(" Confirm ")])],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":item.isActive ? 'green' : 'red'},on:{"click":function($event){$event.stopPropagation();item.statusDialog = true}}},'v-icon',attrs,false),on),[_vm._v(" fas fa-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.isActive ? "Active" : "Inactive"))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":("/customers/" + (item.aspireId) + "/messages/new?recipient=" + (item.propertyManagerId))}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"#31C0E3"}},'v-icon',attrs,false),on),[_vm._v(" mdi-email ")])],1)]}}],null,true)},[_c('span',[_vm._v("Send message")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.hasOverdueRequests)?_c('router-link',{key:"item_has_overdue_requests",attrs:{"to":("/customers/" + (item.aspireId) + "/requests")}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-view-list ")])],1):(item.hasOpenRequests && !item.hasOverdueRequests)?_c('router-link',{key:"item_has_opened_requests",attrs:{"to":("/customers/" + (item.aspireId) + "/requests")}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-view-list ")])],1):(!item.hasOpenRequests && !item.hasOverdueRequests)?_c('router-link',{key:"item_doesnt_have_opened_requests_and_doesnt_haveoverdue",attrs:{"to":("/customers/" + (item.aspireId) + "/requests/new")}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"black"}},'v-icon',attrs,false),on),[_vm._v(" mdi-view-list ")])],1):_vm._e()]}}],null,true)},[(!item.hasOpenRequests && !item.hasOverdueRequests)?_c('span',{key:"vif2"},[_vm._v(" Initiate Service Request ")]):(item.hasOpenRequests && !item.hasOverdueRequests)?_c('span',{key:"vif3"},[_vm._v(" Open Service Request(s) ")]):(item.hasOverdueRequests)?_c('span',{key:"vif4"},[_vm._v(" Overdue Service Request(s) ")]):_vm._e()])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }