<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <p class="text-h6">Customer Landscape Quality Sentiment</p>
        <v-row>
          <v-col class="d-flex align-center" cols="12" md="12">
            <v-rating
              v-model="rating"
              hover
              half-increments
              length="5"
              readonly
            >
              <template v-slot:item="props">
                <img
                  height="58"
                  width="49"
                  :src="
                    props.isFilled
                      ? 'media/assets/active-leaf.png'
                      : props.isHalfFilled
                      ? 'media/assets/half-leaf.png'
                      : 'media/assets/inactive-leaf.png'
                  "
                />
              </template>
            </v-rating>
            <div
              class="sentiment-number-box ml-5 pa-3 rounded-circle d-flex justify-center align-center black--text"
            >
              <span class="text-h5 font-weight-black">{{ rating }}/5</span>
            </div>
          </v-col>
          <!-- <v-col cols="4" md="2" class="d-flex align-center">


          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-radio-group v-model="customerStatus" row dense>
              <v-radio
                label="Show All"
                color="primary"
                value="all"
                class="v-radio-no-margin"
              ></v-radio>
              <v-radio
                label="Show Active"
                color="primary"
                value="active"
                class="v-radio-no-margin"
              ></v-radio>
              <v-radio
                label="Show Inactive"
                color="primary"
                value="inactive"
                class="v-radio-no-margin"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-select
              dense
              v-model="viewByBranch"
              :items="branchesItems"
              item-text="name"
              item-value="value"
              label="View By Branch..."
            ></v-select
          ></v-col>
          <v-col cols="12" sm="2" md="3">
            <router-link
              class="ml-4"
              v-if="
                permissions.quarterlyPlanTemplate &&
                  permissions.quarterlyPlanTemplate.canCreate
              "
              :to="`/planTemplates`"
            >
              <v-btn class="orange-bg white--text" small
                >Quarterly Plan Templates</v-btn
              >
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" md="4" class="d-flex">
        <v-spacer></v-spacer>
        <div>
          <v-img
            src="media/assets/customers-legend.png"
            max-height="150"
            max-width="200"
          ></v-img>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="customers"
      item-key="id"
      :loading="isLoading"
      :server-items-length="totalCustomers"
      :options.sync="options"
      loading-text="Loading... Please wait"
      class="customers-table elevation-1"
    >
      <template #[`item.sentimentWarning`]="{ item }">
        <router-link :to="`/customers/${item.aspireId}`">
          <img
            v-if="item.isActive && item.sentiment.value < 4"
            key="sentiment_less_4"
            width="17px"
            height="20px"
            src="media/assets/orange-leaf.png"
          />
        </router-link>
      </template>
      <template #[`item.communityName`]="{ item }">
        <router-link :to="`/customers/${item.aspireId}`" class="blue-color">
          {{ item.communityName }}
        </router-link>
      </template>
      <template #[`item.propertyManagerEmail`]="{ item }">
        <a :href="'mailto:' + item.propertyManagerEmail" class="blue-color">{{
          item.propertyManagerEmail
        }}</a>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-dialog v-model="item.statusDialog" persistent max-width="290">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text
              >Are you sure you want to make customer {{ item.communityName }}
              {{ item.isActive ? "inactive" : "active" }}?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!statusProcessing"
                key="status_processing"
                color="green darken-1"
                text
                @click="item.statusDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="toggleStatus(item)"
                :loading="statusProcessing"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-tooltip bottom>
          <template #activator="{on, attrs}">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              small
              :color="item.isActive ? 'green' : 'red'"
              @click.stop="item.statusDialog = true"
            >
              fas fa-circle
            </v-icon>
          </template>
          <span>{{ item.isActive ? "Active" : "Inactive" }}</span>
        </v-tooltip>
        &nbsp;
        <v-tooltip bottom>
          <template #activator="{on, attrs}">
            <router-link
              :to="
                `/customers/${item.aspireId}/messages/new?recipient=${item.propertyManagerId}`
              "
            >
              <v-icon
                small
                class="mr-2"
                color="#31C0E3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-email
              </v-icon>
            </router-link>
          </template>
          <span>Send message</span>
        </v-tooltip>
        &nbsp;
        <v-tooltip bottom>
          <template #activator="{on, attrs}">
            <router-link
              v-if="item.hasOverdueRequests"
              key="item_has_overdue_requests"
              :to="`/customers/${item.aspireId}/requests`"
            >
              <v-icon small class="mr-2" color="red" v-bind="attrs" v-on="on">
                mdi-view-list
              </v-icon>
            </router-link>
            <router-link
              v-else-if="item.hasOpenRequests && !item.hasOverdueRequests"
              key="item_has_opened_requests"
              :to="`/customers/${item.aspireId}/requests`"
            >
              <v-icon small class="mr-2" color="green" v-bind="attrs" v-on="on">
                mdi-view-list
              </v-icon>
            </router-link>
            <router-link
              v-else-if="!item.hasOpenRequests && !item.hasOverdueRequests"
              key="item_doesnt_have_opened_requests_and_doesnt_haveoverdue"
              :to="`/customers/${item.aspireId}/requests/new`"
            >
              <v-icon small class="mr-2" color="black" v-bind="attrs" v-on="on">
                mdi-view-list
              </v-icon>
            </router-link>
          </template>
          <span
            v-if="!item.hasOpenRequests && !item.hasOverdueRequests"
            key="vif2"
          >
            Initiate Service Request
          </span>
          <span
            v-else-if="item.hasOpenRequests && !item.hasOverdueRequests"
            key="vif3"
          >
            Open Service Request(s)
          </span>
          <span v-else-if="item.hasOverdueRequests" key="vif4">
            Overdue Service Request(s)
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  GET_QUERY,
  GET_PAGED,
  POST_DATA,
  API_CUSTOMERS,
  API_SERVICE_REQUESTS
} from "@/core/store/crud.module";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { mdiViewListOutline } from "@mdi/js";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  data: () => ({
    isLoading: false,
    headers: [
      {
        value: "sentimentWarning",
        sortable: false,
        width: "20px",
        class: "orange-bg"
      },
      {
        text: "Community",
        value: "communityName",
        class: "orange-bg"
      },
      {
        text: "Property Manager",
        align: "start",
        value: "propertyManagerName",
        class: "orange-bg"
      },
      {
        text: "Management Co.",
        value: "companyName",
        class: "orange-bg"
      },
      { text: "Email", value: "propertyManagerEmail", class: "orange-bg" },
      { text: "Phone", value: "propertyManagerPhone", class: "orange-bg" },
      { text: "Actions", value: "actions", class: "orange-bg", sortable: false }
    ],
    rating: 4.5,
    customerStatus: "active",
    viewByBranch: "all",
    branchesItems: [{ name: "All Branches", value: "all" }],
    customers: [],
    totalCustomers: 10,
    customersQuery: "",
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["communityName"],
      sortDesc: [false]
    },
    sendRequestIcon: mdiViewListOutline,
    serviceRequestsLoading: false,
    statusProcessing: false,
    permissions: {},
    isMounted: false
  }),
  watch: {
    customersQuery: async function() {
      if (!this.isMounted) return;
      await this.getCustomers();
      // if (this.customersQuery && this.customersQuery.length > 0) {
      //   await this.getCustomers();
      // } else {
      //   this.customers = [
      //     {
      //       name: "new customer query",
      //       community: "Northwoods Glenn",
      //       managementCompany: "Diamond Property Mangement",
      //       email: "email@email.com",
      //       phone: "555-555-5555"
      //     }
      //   ];
      // }
    },
    customerStatus: async function() {
      if (!this.isMounted) return;
      await this.getCustomers();
    },
    viewByBranch: async function() {
      if (!this.isMounted) return;
      await this.getCustomers();
    },
    options: async function() {
      if (!this.isMounted) return;
      await this.getCustomers();
      // if (this.customersQuery && this.customersQuery.length > 0) {
      //   await this.getCustomers();
      // } else {
      //   this.customers = [
      //     {
      //       name: "new customer sort",
      //       community: "Northwoods Glenn",
      //       managementCompany: "Diamond Property Mangement",
      //       email: "email@email.com",
      //       phone: "555-555-5555"
      //     }
      //   ];
      // }
    }
  },
  async mounted() {
    this.isMounted = false;
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Customers" }]);
    await permissionsHelper.getPermissions().then(this.getPermissions);
    await this.getBranches();
    await this.getCustomers();
    this.isMounted = true;
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    toggleStatus(item) {
      if (this.statusProcessing) return;

      this.statusProcessing = true;

      item.isActive = !item.isActive;

      let url = `${API_CUSTOMERS}/${item.aspireId}/${
        item.isActive ? "activate" : "deactivate"
      }`;
      this.$store
        .dispatch(POST_DATA, {
          listName: url
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            // item.isActive = response.data.isActive;
            this.$snackbar.showMessage({
              content: "Community has been updated"
            });
          }
          this.statusProcessing = false;
          item.statusDialog = false;
        });
    },

    async getBranches() {
      let self = this;

      this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS + "/branches"
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            let branches = response.data.$values;
            self.branchesItems = [{ name: "All Branches", value: "all" }];
            branches.forEach(el => {
              if (self.branchesItems.indexOf(branch => branch.name == el) < 0) {
                self.branchesItems.push({
                  name: el,
                  value: el
                });
              }
            });
          }
        });
    },

    async getCustomersPromise() {
      return this.$store.dispatch(GET_PAGED, {
        listName: API_CUSTOMERS,
        params: {
          customerStatus: this.customerStatus,
          branch: this.viewByBranch
        }
      });
    },
    async getCustomers() {
      let self = this;
      self.loading = true;
      self.isLoading = true;

      Promise.allSettled([
        this.getCustomersPromise()
        // this.getServiceRequestsPromise()
      ]).then(function(values) {
        let customersResult = values[0].value;
        // let requestsResult = values[1].value;

        let customers = [];
        // let requests = [];

        if (customersResult.status >= 200 && customersResult.status <= 204) {
          customers = customersResult.data.$values;
        } else {
          self.$snackbar.showMessage({
            content:
              customersResult.data ||
              "Cannot receive customers list. Something went wrong!",
            color: "red"
          });
        }

        // if (requestsResult.status >= 200 && requestsResult.status <= 204) {
        //   requests = requestsResult.data.$values;
        // }

        const { sortBy, sortDesc, page, itemsPerPage } = self.options;

        // self.branchesItems = [{ name: "All Branches", value: "all" }];
        customers.forEach(el => {
          // el.hasOverdueRequests = false;
          // el.hasOpenRequests = false;
          el.statusDialog = false;

          // let filteredItems = requests.filter(
          //   itemEl => itemEl.communityId === el.aspireId
          // );
          // if (
          //   filteredItems.filter(
          //     fEl =>
          //       fEl.isOpen && new Date(fEl.targetCompletionDate) < new Date()
          //   ).length > 0
          // ) {
          //   el.hasOverdueRequests = true;
          // } else if (filteredItems.filter(fEl => !fEl.isCompleted).length > 0) {
          //   el.hasOpenRequests = true;
          // }
        });

        // let filteredItems = [];
        if (self.customerStatus && self.customerStatus !== "all") {
          let useIsActive = self.customerStatus === "active";

          customers = customers.filter(el => el.isActive === useIsActive);
          // for (let i = 0; i < items.length; i++) {
          //   if (items[i].isActive === useIsActive)
          //     filteredItems.push(items[i]);
          // }

          // items = filteredItems;
        }

        if (self.viewByBranch && self.viewByBranch !== "all") {
          customers = customers.filter(
            el => el.branchName === self.viewByBranch
          );
          // for (let i = 0; i < items.length; i++) {
          //   if (items[i].isActive === useIsActive)
          //     filteredItems.push(items[i]);
          // }

          // items = filteredItems;
        }

        const total = customers.length;

        let combinedRating = 0;
        let totalActive = 0;
        customers.forEach(element => {
          combinedRating += element.isActive ? element.sentiment.value : 0;
          totalActive += element.isActive ? 1 : 0;
        });

        if (totalActive !== 0) {
          combinedRating =
            Math.round((combinedRating * 10 * 1.0) / totalActive) / 10;
        }

        if (sortBy.length === 1 && sortDesc.length === 1) {
          customers = customers.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              return 0;
            } else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              return 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          customers = customers.slice(
            (page - 1) * itemsPerPage,
            page * itemsPerPage
          );
        }

        self.customers = customers;
        self.totalCustomers = total;
        self.rating = combinedRating;

        self.loading = false;
        self.isLoading = false;
      });
    },

    async getServiceRequestsPromise() {
      return this.$store.dispatch(GET_PAGED, {
        listName: `${API_SERVICE_REQUESTS}/User/0`,
        params: { shortFormat: true }
      });
    }
    // async getServiceRequests() {
    //   // if (self.serviceRequestsLoading) return;

    //   // self.serviceRequestsLoading = true;

    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: `${API_SERVICE_REQUESTS}/User/0`
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         let items = response.data.$values;

    //         return Promise.resolve(items);
    //       }

    //       // self.$nextTick(function() {
    //       //   self.serviceRequestsLoading = false;
    //       // });
    //     })
    //     .catch(err => {
    //       this.$snackbar.showMessage({
    //         content: err
    //       });

    //       return Promise.resolve([]);
    //       // self.$nextTick(function() {
    //       //   self.serviceRequestsLoading = false;
    //       // });
    //     });
    // }
  },
  computed: {
    sort() {
      if (
        this.options.sortBy &&
        this.options.sortBy[0] &&
        this.options.sortBy[0].length > 0
      ) {
        if (this.options.sortBy[0] === "fullName") {
          return `firstName ${this.options.sortDesc[0] ? "desc" : "asc"}`;
        } else {
          return `${this.options.sortBy[0]} ${
            this.options.sortDesc[0] ? "desc" : "asc"
          }`;
        }
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/src/assets/sass/custom/custom.scss";

.sentiment-number-box {
  width: 80px;
  height: 80px;
  border: solid 2px black;
}
.v-input--selection-controls .v-radio > .v-label {
  margin-bottom: 0px !important;
}
</style>
